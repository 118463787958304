import { SET_SELECTED_BRANCHES } from '../actionTypes';

const selectedbranches = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_BRANCHES:
      return action.branches;
    default:
      return state;
  }
};

export default selectedbranches;
