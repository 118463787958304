import { SET_DATAGRID } from '../actionTypes';

const datagrid = (state = {}, action) => {
  switch (action.type) {
    case SET_DATAGRID:
      return {
        ...state,
        id: action.id,
        caption: action.caption,
        columnState: action.columnState,
        columnGroupState: action.columnGroupState,
        filter: action.filter,
        isPivotMode: action.isPivotMode,
        type: action.dashboardType,
        order: action.order,
      };
    default:
      return state;
  }
};

export default datagrid;
