import { SET_IS_DISABLED_SAVE_BUTTON } from '../actionTypes';

const isDisabledSaveButton = (state = true, action) => {
  switch (action.type) {
    case SET_IS_DISABLED_SAVE_BUTTON:
      return action.isDisabledSaveButton;
    default:
      return state;
  }
};

export default isDisabledSaveButton;
