import { SET_CURRENT_IMAGES } from '../actionTypes';

const currentImages = (state = [], action) => {
  switch (action.type) {
    case SET_CURRENT_IMAGES:
      return action.currentImages;
    default:
      return state;
  }
};

export default currentImages;
