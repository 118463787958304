import { SET_ORDER_SELECTION } from '../actionTypes';

const orderSelection = (state = {}, action) => {
  switch (action.type) {
    case SET_ORDER_SELECTION:
      if (state[action.payload.orderNumber] === undefined) {
        state[action.payload.orderNumber] = {};
      }

      state[action.payload.orderNumber][action.payload.key] =
        action.payload.values;

      return state;
    default:
      return state;
  }
};

export const SELECTION_ACTIVITIES_DATERANGE =
  'activities-datetimerange-selection';

export default orderSelection;
