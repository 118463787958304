export const SET_JWT = 'SET_JWT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_SETTINGS = 'SET_CURRENT_USER_SETTINGS';
export const SET_PERMISSIONS_OF_USER = 'SET_PERMISSIONS_OF_USER';
export const SET_TOUCH_INPUT_PARTNUMBER = 'SET_TOUCH_INPUT_PARTNUMBER';
export const SET_TOUCH_INPUT_ORDER = 'SET_TOUCH_INPUT_ORDER';
export const SET_TOUCH_INPUT_EMPLOYEE = 'SET_TOUCH_INPUT_EMPLOYEE';
export const SET_CURRENT_IMAGES = 'SET_CURRENT_IMAGES';
export const SET_PART_IMAGES = 'SET_PART_IMAGES';
export const SET_DEVICE_SETUP = 'SET_DEVICE_SETUP';
export const SET_DEVICE_LANGUAGE = 'SET_DEVICE_LANGUAGE';
export const SET_SCANCODE = 'SET_SCANCODE';
export const INITIALIZE_ACTIVITY = 'INITIALIZE_ACTIVITY';
export const ADD_ACTIVITY_VALUE = 'ADD_ACTIVITY_VALUE';
export const ADD_ACTIVITY_INFOS = 'ADD_ACTIVITY_INFOS';
export const ADD_ACTIVITY_META = 'ADD_ACTIVITY_META';
export const UPDATE_ACTIVITY_META = 'UPDATE_ACTIVITY_META';
export const ADD_ACTIVITY_CHARACTERISTIC = 'ADD_ACTIVITY_CHARACTERISTIC';
export const ADD_ACTIVITY_FINISH = 'ADD_ACTIVITY_FINISH';
export const SET_SELECTED_PIVOT_ITEM = 'SET_SELECTED_PIVOT_ITEM';
export const SET_MAIN_ACTIVITY = 'SET_MAIN_ACTIVITY';
export const SET_IS_DISABLED_SAVE_BUTTON = 'SET_IS_DISABLED_SAVE_BUTTON';
export const SET_IS_PART_DETECTION = 'SET_IS_PART_DETECTION';
export const SET_DMC = 'SET_DMC';
export const SET_CLOSE_CHARACTERISTICS_SLIDER_ICON =
  'SET_CLOSE_CHARACTERISTICS_SLIDER_ICON';
export const SET_META_INPUT = 'SET_META_INPUT';
export const REMOVE_META_INPUT = 'REMOVE_META_INPUT';
export const REMOVE_META_INPUT_ALL = 'REMOVE_META_INPUT_ALL';
export const SET_LAST_PARTNUMBER = 'SET_LAST_PARTNUMBER';
export const SET_PARTNUMBER_HAS_CHANGED = 'SET_PARTNUMBER_HAS_CHANGED';
export const SET_META_REQUIRED = 'SET_META_REQUIRED';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_DASHBOARD_ROWS = 'SET_DASHBOARD_ROWS';
export const SET_ORDER_SELECTION = 'SET_ORDER_SELECTION';
export const UPDATE_TILE = 'UPDATE_TILE';
export const SET_SCANINPUT_CAMERA = 'SET_SCANINPUT_CAMERA';
export const SET_DATAGRID = 'SET_DATAGRID';
export const SET_SELECTED_BRANCHES = 'SET_SELECTED_BRANCHES';
export const SET_SELECTED_SUBSIDIARIES = 'SET_SELECTED_SUBSIDIARIES';
