const arraysAreEqual = (array1, array2) => {
  // Konvertieren der Arrays in JSON-Strings
  const json1 = JSON.stringify(array1);
  const json2 = JSON.stringify(array2);

  // Vergleichen der JSON-Strings
  return json1 === json2;
};

export default arraysAreEqual;
