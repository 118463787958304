import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import StateContainer from './containers/StateContainer';
import GraphQLContainer from './containers/GraphQLContainer';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './index.css';
import Pages from './pages/Pages';
import PWAContainer from './containers/PWAContainer';
initializeIcons();

ReactDOM.render(
  <React.StrictMode>
    <StateContainer>
      <PWAContainer>
        <GraphQLContainer>
          <Pages />
        </GraphQLContainer>
      </PWAContainer>
    </StateContainer>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
