import { SET_PART_IMAGES } from '../actionTypes';

const partImages = (state = [], action) => {
  switch (action.type) {
    case SET_PART_IMAGES:
      return action.partImages;
    default:
      return state;
  }
};

export default partImages;
