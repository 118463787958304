import { createSlice } from '@reduxjs/toolkit';

const initialCharacteristicModalData = {
  activityCharacteristicId: undefined,
  characteristicVariantId: undefined,
  quantity: '',
  isReworked: true,
  metas: [],
  characteristicVariant: undefined,
};

const initialState = {
  partnumber: undefined,
  employee: null,
  price: null,
  quantity: 0,
  okCalc: 0,
  nokCalc: 0,
  reworkCalc: 0,
  amountCalc: null,
  startedAt: undefined,
  finishedAt: undefined,
  createdAt: undefined,
  createdBy: undefined,
  sendAt: null,
  sendBy: null,
  // deletedAt: null,
  // deletedBy: null,
  invoiceId: null,
  metas: [],
  characteristics: [],
  isSubmitCalled: false,
  isCharacteristicModalOpen: false,
  characteristicModalData: initialCharacteristicModalData,
};

// const validNumber = (num) => {
//   num = parseInt(num);
//   if (isNaN(num)) num = 0;

//   return num;
// };

export const activityFormSlice = createSlice({
  name: 'activityForm',
  initialState,
  reducers: {
    initialize: () => initialState,
    setPartnumber: (state, action) => {
      state.partnumber = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    changeQuantity: (state, action) => {
      const newValue = parseInt(action.payload);
      state.quantity = newValue;
      state.okCalc = newValue - state.nokCalc - state.reworkCalc;
    },
    changeNokTotal: (state, action) => {
      const newValue = parseInt(action.payload);
      state.nokCalc = newValue - state.reworkCalc;
      state.okCalc = state.quantity - newValue;
    },
    changeNA: (state, action) => {
      const newValue = parseInt(action.payload);
      state.reworkCalc = newValue;
      state.nokCalc = state.quantity - state.okCalc - newValue;
    },
    changeStartedAt: (state, action) => {
      state.startedAt = action.payload;
    },
    changeFinishedAt: (state, action) => {
      state.finishedAt = action.payload;
    },
    changeMeta: (state, action) => {
      const metas = [...state.metas];
      const foundIndex = metas.findIndex(
        (meta) => meta.metaId === action.payload.metaId
      );
      if (foundIndex > -1) {
        metas[foundIndex] = action.payload;
      } else {
        metas.push(action.payload);
      }
      state.metas = metas;
    },
    setMetas: (state, action) => {
      state.metas = action.payload;
    },
    changeCharacteristic: (state, action) => {
      const characteristics = [...state.characteristics];
      const foundIndex = characteristics.findIndex(
        (characteristic) =>
          characteristic.activityCharacteristicId ===
          action.payload.activityCharacteristicId
      );
      if (foundIndex > -1) {
        characteristics[foundIndex] = action.payload;
      } else {
        const findFreeNumber = (array) => {
          for (let i = 1; i <= array.length + 2; i++) {
            if (
              !array.some(
                (characteristic) =>
                  i === characteristic.activityCharacteristicId
              )
            ) {
              return i;
            }
          }
        };

        characteristics.push({
          ...action.payload,
          activityCharacteristicId: findFreeNumber(characteristics),
        });
      }
      state.characteristics = characteristics;
      // state.characteristics.push(action.payload);
    },
    removeCharacteristic: (state, action) => {
      state.characteristics.splice(
        state.characteristics.findIndex(
          (characteristic) =>
            characteristic.activityCharacteristicId === action.payload
        ),
        1
      );
    },
    callSubmit: (state) => {
      state.isSubmitCalled = true;
    },
    clearSubmit: (state) => {
      state.isSubmitCalled = false;
    },
    openCharacteristicModal: (state, action) => {
      state.isCharacteristicModalOpen = true;
      state.characteristicModalData =
        action.payload || initialCharacteristicModalData;
    },
    closeCharacteristicModal: (state) => {
      state.isCharacteristicModalOpen = false;
      state.characteristicModalData = initialCharacteristicModalData;
    },
    changeCharacteristicModalData: (state, action) => {
      if (action.payload.characteristicVariant)
        state.characteristicModalData.characteristicVariant =
          action.payload.characteristicVariant;
      if (action.payload.quantity)
        state.characteristicModalData.quantity = action.payload.quantity;
      if (action.payload.isReworked !== undefined)
        state.characteristicModalData.isReworked = action.payload.isReworked;
      if (action.payload.metas)
        state.characteristicModalData.metas = action.payload.metas;
    },
  },
});

export const {
  initialize,
  setPartnumber,
  setEmployee,
  changeQuantity,
  changeNokTotal,
  changeNA,
  changeStartedAt,
  changeFinishedAt,
  changeMeta,
  setMetas,
  changeCharacteristic,
  removeCharacteristic,
  callSubmit,
  clearSubmit,
  openCharacteristicModal,
  closeCharacteristicModal,
  changeCharacteristicModalData,
} = activityFormSlice.actions;

export default activityFormSlice.reducer;
