import {
  SET_LAST_PARTNUMBER,
  REMOVE_META_INPUT,
  SET_META_INPUT,
  SET_PARTNUMBER_HAS_CHANGED,
  REMOVE_META_INPUT_ALL,
  SET_META_REQUIRED,
} from '../actionTypes';

const metaInput = (
  state = {
    lastPartnumber: {},
    changedPartnumber: false,
    hasRequired: false,
    lockedData: [],
  },
  action
) => {
  switch (action.type) {
    case SET_META_INPUT: {
      const index = state.lockedData
        .map(function (e) {
          return e.id;
        })
        .indexOf(action.payload.new.id);

      if (index >= 0) {
        const newArray = [...state.lockedData];
        newArray[index] = action.payload.new;

        return {
          ...state,
          lockedData: newArray,
        };
      }

      return {
        ...state,
        lockedData: [...state.lockedData, action.payload.new],
      };
    }

    case REMOVE_META_INPUT: {
      return {
        ...state,
        lockedData: state.lockedData.filter(
          (entry) => entry.id !== action.payload.removeId
        ),
      };
    }

    case REMOVE_META_INPUT_ALL: {
      return {
        ...state,
        lockedData: [],
      };
    }

    case SET_LAST_PARTNUMBER: {
      return {
        ...state,
        lastPartnumber: action.payload.lastPartnumber,
      };
    }

    case SET_PARTNUMBER_HAS_CHANGED: {
      return {
        ...state,
        changedPartnumber: action.payload.changedPartnumber,
      };
    }

    case SET_META_REQUIRED: {
      return {
        ...state,
        hasRequired: action.payload.hasRequired,
      };
    }

    default:
      return state;
  }
};

export default metaInput;
