import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setPWAState,
  setPromptInstall,
  setSupportsPWA,
} from '../redux/slices/pwa';

const PWAContainer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      dispatch(setSupportsPWA(true));
      dispatch(setPromptInstall(e));
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, [dispatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isPWA = urlParams.get('isPWA');
    dispatch(setPWAState(isPWA === 'true'));
  }, [dispatch]);

  return <div>{children}</div>;
};

export default PWAContainer;
