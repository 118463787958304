import { createSlice } from '@reduxjs/toolkit';

export const pwaSlice = createSlice({
  name: 'pwaState',
  initialState: {
    isInstalledAsPWA: false,
    supportsPWA: false,
    promptInstall: null,
  },
  reducers: {
    setPWAState: (state, action) => {
      state.isInstalledAsPWA = action.payload;
    },
    setSupportsPWA: (state, action) => {
      state.supportsPWA = action.payload;
    },
    setPromptInstall: (state, action) => {
      state.promptInstall = action.payload;
    },
  },
});

export const { setPWAState, setSupportsPWA, setPromptInstall } =
  pwaSlice.actions;

export default pwaSlice.reducer;
