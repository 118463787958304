import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panelStatus: false,
  errorMessage: '',
  customerSelectionPanelStatus: false,
  customer: '',
  customerComment: '',
  customerContactPerson: '',
  customerContactPersonEmail: '',
  isWorksitePanelOpen: false,
  worksite: undefined,
  isWorksiteContactPersonPanelOpen: false,
  worksiteContactPerson: undefined,
  worksiteContactPersonName: '',
  worksiteContactPersonEmail: '',
  start: new Date().toLocaleDateString('en-CA'),
  errorDescription: '',
  workToBePerformed: '',
  numberOfPieces: '',
  numberOfPersons: '',
  measuringTestingEquipmentTools: '',
  problemSheetNumber: '',
  comment: '',
  emailDistributionList: [],
  vehicleType: '',
};

export const addOrderSlice = createSlice({
  name: 'addOrderForm',
  initialState,
  reducers: {
    openPanel: (state) => {
      state.panelStatus = true;
    },
    dismissPanel: () => initialState,
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setWorksite: (state, action) => {
      state.worksite = action.payload;
      state.worksiteContactPerson = undefined;
      state.isWorksitePanelOpen = false;
    },
    openWorksitePanel: (state) => {
      state.isWorksitePanelOpen = true;
    },
    dismissWorksitePanel: (state) => {
      state.isWorksitePanelOpen = false;
    },
    setWorksiteContactPerson: (state, action) => {
      state.worksiteContactPerson = action.payload;
      state.isWorksiteContactPersonPanelOpen = false;
      state.worksiteContactPersonName = '';
      state.worksiteContactPersonEmail = '';
    },
    resetWorksiteContactPerson: (state) => {
      state.worksiteContactPerson = undefined;
      state.worksiteContactPersonName = '';
      state.worksiteContactPersonEmail = '';
    },
    openWorksiteContactPersonPanel: (state) => {
      state.isWorksiteContactPersonPanelOpen = true;
    },
    dismissWorksiteContactPersonPanel: (state) => {
      state.isWorksiteContactPersonPanelOpen = false;
    },
    initialize: () => initialState,
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  openPanel,
  dismissPanel,
  updateField,
  setCustomer,
  setWorksite,
  openWorksitePanel,
  dismissWorksitePanel,
  setWorksiteContactPerson,
  resetWorksiteContactPerson,
  openWorksiteContactPersonPanel,
  dismissWorksiteContactPersonPanel,
  initialize,
  setErrorMessage,
} = addOrderSlice.actions;

export default addOrderSlice.reducer;
