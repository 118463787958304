import {
  SET_DMC,
  SET_IS_PART_DETECTION,
  SET_TOUCH_INPUT_EMPLOYEE,
  SET_TOUCH_INPUT_ORDER,
  SET_TOUCH_INPUT_PARTNUMBER,
} from '../actionTypes';

const touchInput = (
  state = {
    isPartDetection: false,
    employee: null,
    order: null,
    partnumber: null,
  },
  action
) => {
  switch (action.type) {
    case SET_TOUCH_INPUT_EMPLOYEE:
      return { ...state, employee: action.employee };
    case SET_TOUCH_INPUT_ORDER:
      if (action.order) {
        const { metaOrders, ...oldOrder } = action.order;
        const newMetaOrders = metaOrders.map((element) => {
          const { meta, ...oldElement } = element;
          const { id, ...oldMeta } = meta;
          return Object.assign(oldElement, {
            meta: Object.assign(oldMeta, { id: parseInt(id) }),
          });
        });
        return {
          ...state,
          order: Object.assign(oldOrder, { metaOrders: newMetaOrders }),
        };
      } else {
        return { ...state, order: action.order };
      }
    case SET_IS_PART_DETECTION:
      return { ...state, isPartDetection: action.isPartDetection };
    case SET_TOUCH_INPUT_PARTNUMBER:
      return { ...state, partnumber: action.partnumber };
    case SET_DMC:
      return { ...state, dmc: action.dmc };
    case 'RESET_TOUCH_INPUT':
      return state; // TODO...
    default:
      return state;
  }
};

export default touchInput;
