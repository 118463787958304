import { useTranslation } from 'react-i18next';

export function Loading() {
  return (
    <div className="loading">
      <img src="/images/loading.svg" alt="Loading…"></img>
    </div>
  );
}

export function InlineLoading() {
  const { t } = useTranslation();
  return <div className="inline-loading">{t('Loading…')}</div>;
}
