import { SET_DEVICE_SETUP } from '../actionTypes';

const deviceSetup = (state = '', action) => {
  switch (action.type) {
    case SET_DEVICE_SETUP:
      return action.deviceSetup;
    default:
      return state;
  }
};

export default deviceSetup;
