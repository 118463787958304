import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formId: undefined,
  isPanelOpen: false,
  id: undefined,
  customer: undefined,
  isCustomerEditable: true,
  isCustomerPanelOpen: false,
  isCustomerNoteOpen: false,
  customerContactPerson: undefined,
  isCustomerContactPersonPanelOpen: false,
  invoiceAddress: undefined,
  worksite: undefined,
  isWorksitePanelOpen: false,
  worksiteContactPerson: undefined,
  isWorksiteContactPersonPanelOpen: false,
  employee: undefined,
  branch: undefined,
  orderNumber: undefined,
  hasAnInvoiceAddress: false,
  paymentByCustomer: false,
  invoiceAddressAddition: '',
  invoiceTo: 1,
  paymentByAddress: undefined,
  acceptedOn: new Date().toLocaleDateString('en-CA'),
  start: new Date().toLocaleDateString('en-CA'),
  end: undefined,
  tool: '',
  supplies: '',
  testingEquipment: '',
  drawings: '',
  engineeringData: '',
  numberOfPersons: '',
  comment: '',
  oemComment: '',
  numberOfPiecesText: '',
  numberOfPieces: 0,
  errorDescription: '',
  workToBePerformed: '',
  existsConfirmation: false,
  noReport: false,
  earlyReport: false,
  earlyReportTargetTime: false,
  dailyReportToCustomer: false,
  weeklyReportToCustomer: false,
  finalReportToCustomer: false,
  dailyReportToWorksite: false,
  weeklyReportToWorksite: false,
  finalReportToWorksite: false,
  language: 'de',
  isResidentOrder: false,
  keyword: '',
  noteTimeRecording: '',
  noteMailDistributionList: '',
  noteInvoice: '',
  noteTestReports: '',
  temporaryData: undefined,
  isSubmitCalled: false,
};

export const orderFormSlice = createSlice({
  name: 'orderForm',
  initialState,
  reducers: {
    openPanel: (state) => {
      state.isPanelOpen = true;
    },
    dismissPanel: (state) => {
      state.isPanelOpen = false;
    },
    setOrder: (_, action) => {
      return action.payload;
    },
    openCustomerPanel: (state) => {
      state.isCustomerPanelOpen = true;
    },
    dismissCustomerPanel: (state) => {
      state.isCustomerPanelOpen = false;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
      delete state.temporaryData.customerName;
      state.isCustomerPanelOpen = false;
      if (action.payload.noteOnOrderCreation.length > 0) {
        state.isCustomerNoteOpen = true;
      }
      if (action.payload.noteInvoice.length > 0) {
        state.noteInvoice = action.payload.noteInvoice;
      }
      if (action.payload.noteTimeRecording.length > 0) {
        state.noteTimeRecording = action.payload.noteTimeRecording;
      }
      if (action.payload.noteMailDistributionList.length > 0) {
        state.noteMailDistributionList =
          action.payload.noteMailDistributionList;
      }
      if (action.payload.noteTestReports.length > 0) {
        state.noteTestReports = action.payload.noteTestReports;
      }
    },
    openCustomerNote: (state) => {
      state.isCustomerNoteOpen = true;
    },
    dismissCustomerNote: (state) => {
      state.isCustomerNoteOpen = false;
    },
    openCustomerContactPersonPanel: (state) => {
      state.isCustomerContactPersonPanelOpen = true;
    },
    dismissCustomerContactPersonPanel: (state) => {
      state.isCustomerContactPersonPanelOpen = false;
    },
    setCustomerContactPerson: (state, action) => {
      state.customerContactPerson = action.payload;
      delete state.temporaryData.customerContactPersonName;
      delete state.temporaryData.customerContactPersonEmail;
      state.isCustomerContactPersonPanelOpen = false;
    },
    openWorksitePanel: (state) => {
      state.isWorksitePanelOpen = true;
    },
    dismissWorksitePanel: (state) => {
      state.isWorksitePanelOpen = false;
    },
    setWorksite: (state, action) => {
      state.worksite = action.payload;
      state.isWorksitePanelOpen = false;
    },
    openWorksiteContactPersonPanel: (state) => {
      state.isWorksiteContactPersonPanelOpen = true;
    },
    dismissWorksiteContactPersonPanel: (state) => {
      state.isWorksiteContactPersonPanelOpen = false;
    },
    setWorksiteContactPerson: (state, action) => {
      state.worksiteContactPerson = action.payload;
      state.isWorksiteContactPersonPanelOpen = false;
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
    callSubmit: (state) => {
      state.isSubmitCalled = true;
    },
    cancelSubmit: (state) => {
      state.isSubmitCalled = false;
    },
  },
});

export const {
  openPanel,
  dismissPanel,
  setOrder,
  openCustomerPanel,
  dismissCustomerPanel,
  setCustomer,
  openCustomerNote,
  dismissCustomerNote,
  openCustomerContactPersonPanel,
  dismissCustomerContactPersonPanel,
  setCustomerContactPerson,
  openWorksitePanel,
  dismissWorksitePanel,
  setWorksite,
  openWorksiteContactPersonPanel,
  dismissWorksiteContactPersonPanel,
  setWorksiteContactPerson,
  updateField,
  callSubmit,
  cancelSubmit,
} = orderFormSlice.actions;

export default orderFormSlice.reducer;
