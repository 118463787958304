import { SET_SELECTED_PIVOT_ITEM } from '../actionTypes';

const selectedPivot = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_PIVOT_ITEM:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};

export default selectedPivot;
