import { SET_DASHBOARD, SET_DASHBOARD_ROWS, UPDATE_TILE } from '../actionTypes';

const dashboard = (
  state = {
    content: {
      rows: [],
    },
  },
  action
) => {
  switch (action.type) {
    case SET_DASHBOARD:
      return {
        ...state,
        id: action.id,
        caption: action.caption,
        content: action.content,
        type: action.dashboardType,
        order: action.order,
      };
    case SET_DASHBOARD_ROWS:
      return {
        ...state,
        content: { rows: action.rows },
      };
    case UPDATE_TILE:
      const updatedRows = state.content.rows?.map((row) => {
        const updatedTiles = row.tiles?.map((tile) => {
          if (tile.id === action.tile.id) {
            return action.tile;
          } else {
            return tile;
          }
        });
        return { ...row, tiles: updatedTiles };
      });
      return { ...state, content: { rows: updatedRows } };
    default:
      return state;
  }
};

export default dashboard;
