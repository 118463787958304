import { SET_SCANINPUT_CAMERA } from '../actionTypes';

const scaninput = (state = null, action) => {
  switch (action.type) {
    case SET_SCANINPUT_CAMERA:
      return action.cameraDeviceId;
    default:
      return state;
  }
};

export default scaninput;
