import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_SETTINGS,
  SET_PERMISSIONS_OF_USER,
} from '../actionTypes';

const currentUser = (state = '', action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.currentUser;
    case SET_PERMISSIONS_OF_USER:
      const permissions = [];
      for (var i = 0; i < action.userRoles.length; i++) {
        const role = action.userRoles[i].role;
        for (var j = 0; j < role.permissions.length; j++) {
          const permission = role.permissions[j];
          permissions.push(permission.machineName);
        }
      }
      return { ...state, permissions: permissions };
    case SET_CURRENT_USER_SETTINGS:
      return { ...state, settings: action.settings };
    default:
      return state;
  }
};

export default currentUser;
