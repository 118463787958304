import localForage from 'localforage';
import persistReducer from 'redux-persist/es/persistReducer';
import rootReducer from './reducers';
import { createStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import persistStore from 'redux-persist/es/persistStore';

const persistConfig = {
  key: 'root',
  storage: localForage,
  whitelist: ['jwt', 'deviceSetup', 'deviceLanguage', 'scaninput'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);

export default function configureStore() {
  return { store: store, persistor: persistor };
}
