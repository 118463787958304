import {
  ADD_ACTIVITY_CHARACTERISTIC,
  ADD_ACTIVITY_FINISH,
  ADD_ACTIVITY_VALUE,
  ADD_ACTIVITY_INFOS,
  ADD_ACTIVITY_META,
  UPDATE_ACTIVITY_META,
  INITIALIZE_ACTIVITY,
} from '../actionTypes';

const activity = (state = {}, action) => {
  switch (action.type) {
    case INITIALIZE_ACTIVITY:
      return {
        partnumber: null,
        employee: null,
        startedAt: null,
        finishedAt: null,
        quantity: null,
        metas: [],
        characteristics: [],
      };
    case ADD_ACTIVITY_VALUE:
      return {
        ...state,
        partnumber: action.activity.partnumber
          ? action.activity.partnumber
          : state.partnumber,
        employee: action.activity.employee
          ? action.activity.employee
          : state.employee,
        startedAt: action.activity.startedAt
          ? action.activity.startedAt
          : state.startedAt,
        quantity: action.activity.quantity
          ? action.activity.quantity
          : state.quantity,
      };
    case ADD_ACTIVITY_INFOS:
      return {
        ...state,
        partnumber: action.partnumber,
        employee: action.employee,
        startedAt: action.startedAt,
        quantity: action.quantity,
        metas: action.metas,
      };
    case ADD_ACTIVITY_META:
      return {
        ...state,
        metas: [...state.metas, action.meta],
      };
    case UPDATE_ACTIVITY_META:
      return {
        ...state,
        metas: state.metas,
      };
    case ADD_ACTIVITY_CHARACTERISTIC:
      let characteristics = state.characteristics?.filter(
        (element) =>
          element.characteristicVariantId !==
          action.characteristic.characteristicVariantId
      );

      let newArray = [];
      if (action.characteristic.quantity !== 0) {
        newArray = [
          ...characteristics,
          {
            characteristicVariantId: parseInt(
              action.characteristic.characteristicVariantId
            ),
            quantity: action.characteristic.quantity,
            isReworked: action.characteristic.isReworked,
            createdAt: action.characteristic.createdAt,
            characteristicGroupId: action.characteristic.characteristicGroupId,
            isBlocking: action.characteristic.isBlocking,
            isReworkable: action.characteristic.isReworkable,
          },
        ];
      } else {
        newArray = [...characteristics];
      }

      return {
        ...state,
        characteristics: newArray,
      };
    case ADD_ACTIVITY_FINISH:
      return {
        ...state,
        finishedAt: action.finishedAt,
      };
    default:
      return state;
  }
};

export default activity;
