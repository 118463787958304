import { SET_MAIN_ACTIVITY } from '../actionTypes';

const mainActivity = (state = {}, action) => {
  switch (action.type) {
    case SET_MAIN_ACTIVITY:
      return action.mainActivity;
    default:
      return state;
  }
};

export default mainActivity;
