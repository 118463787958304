import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { useSelector } from 'react-redux';
import { getMainDefinition } from '@apollo/client/utilities';

export default function GraphQLContainer(props) {
  const jwt = useSelector((state) => state.jwt);

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  });

  const wsLink = new WebSocketLink({
    uri:
      (window.location.protocol === 'https:' ? 'wss' : 'ws') +
      '://' +
      window.location.hostname +
      process.env.REACT_APP_GRAPHQL_WS,
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: jwt ? `Bearer ${jwt}` : null,
      },
      lazy: true,
    },
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : null,
      },
    }));
    return forward(operation);
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: from([errorLink, authMiddleware, link]),
    cache: new InMemoryCache({ resultCaching: false }),
    connectToDevTools: process.env.REACT_APP_APOLLO_DEVTOOLS_ACTIVE,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
