import { createSlice } from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
  name: 'dashboardV2',
  initialState: {
    content: {
      rows: [],
    },
    activeDashboardId: undefined,
    activeDashboardCaption: '',
    defaultContent: {},
    isDefaultAsCurrent: true,
    isEditMode: false,
  },
  reducers: {
    setActiveDashboard: (state, action) => {
      state.content = action.payload.content;
      state.activeDashboardId = action.payload.id;
      state.activeDashboardCaption = action.payload.caption;
      state.isDefaultAsCurrent = false;
    },
    setDashboardContent: (state, action) => {
      state.content = action.payload;
      state.isDefaultAsCurrent = false;
    },
    setDefaultContent: (state, action) => {
      state.defaultContent = action.payload;
      if (state.isDefaultAsCurrent) {
        state.content = action.payload;
      }
    },
    setDefaultAsCurrent: (state) => {
      state.isDefaultAsCurrent = true;
      state.content = state.defaultContent;
      state.activeDashboardId = undefined;
      state.activeDashboardCaption = '';
      state.isEditMode = false;
    },
    toggleEditMode: (state) => {
      state.isEditMode = !!!state.isEditMode;
    },
    updateTile: (state, action) => {
      const updatedRows = state.content.rows?.map((row) => {
        const updatedTiles = row.tiles?.map((tile) => {
          if (tile?.id === action.payload.id) {
            return action.payload;
          } else {
            return tile;
          }
        });
        return { ...row, tiles: updatedTiles };
      });
      return { ...state, content: { rows: updatedRows } };
    },
  },
});

export const {
  setActiveDashboard,
  setDashboardContent,
  setDefaultContent,
  setDefaultAsCurrent,
  toggleEditMode,
  updateTile,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
