import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Loading } from './Loading';
const App = lazy(() => import('./App.jsx'));
const Touch = lazy(() => import('./touch/Touch'));

export default function Pages() {
  useEffect(() => {
    let loader = document.querySelector('.loading');
    if (loader != null) loader.remove();
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/touch">
            <Touch />
          </Route>
          <Route>
            <App />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}
