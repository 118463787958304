import { SET_CLOSE_CHARACTERISTICS_SLIDER_ICON } from '../actionTypes';

const closeCharacteristicSliderIcon = (state = false, action) => {
  switch (action.type) {
    case SET_CLOSE_CHARACTERISTICS_SLIDER_ICON:
      return action.closeCharacteristicSliderIcon;
    default:
      return state;
  }
};

export default closeCharacteristicSliderIcon;
